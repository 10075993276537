
import { defineComponent, nextTick, ref, watch } from 'vue';
import * as echarts from 'echarts';
import { queryHistogram } from '@/API/salesReporting';

export default defineComponent({
    props: {
        modalVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        params: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    emits: ['close-modal'],
    setup(props, { emit }) {
        let total: any[] = [];
        let eligibleRetail: any[] = [];
        let eligibleRetailPassingRate: any[] = [];
        let ineligibleRetail: any[] = [];
        const getShowData = () => {
            const {
              bu,
              brand,
              region,
              year,
              dealerCodeCofico: cofico,
              dealerCodeSWT: swt,
              dealerName: cn,
              dealerGroup: group,
              month,
              quarters,
              buId
            } = props.params;
            const params = {
                month,
                bu,
                buId,
                brand,
                region,
                year,
                quarters,
                cofico,
                swt,
                cn,
                group
            };
            return queryHistogram(params).then((res: any) => {
                total = [];
                ineligibleRetail = [];
                eligibleRetail = [];
                eligibleRetailPassingRate = [];
                res.forEach((element: any) => {
                    total.push(element.total);
                    ineligibleRetail.push(element.ineligible);
                    eligibleRetail.push(element.eligibleRetailNo);
                    eligibleRetailPassingRate.push(
                        element.eligibleRetailPassingRate.replace('%', '')
                    );
                });
            });
        };
        const closeModal = () => {
            emit('close-modal');
        };

        const chartRef = ref<HTMLElement>();
        let myChart: echarts.ECharts | null = null;

        const showChart = () => {
            if (chartRef.value) {
                // 绘制图表
                if (!myChart) {
                    myChart = echarts.init(chartRef.value);
                }

                myChart.setOption({
                    xAxis: {
                        type: 'category',
                        data: [
                            'Jan',
                            'Feb',
                            'Mar',
                            'Apr',
                            'May',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Oct',
                            'Nov',
                            'Dec',
                        ],
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: [
                        {
                            type: 'value',
                            show: false,
                        },
                        {
                            type: 'value',
                            show: false,
                            min: 0,
                            max: 100,
                        },
                    ],
                    legend: {
                        // x: 'right',
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    label: {
                        show: true,
                        position: 'top',
                    },
                    series: [
                        {
                            name: 'Ineligible Retail No.',
                            data: ineligibleRetail,
                            type: 'bar',
                            zlevel: 1,
                            barWidth: 20,
                            color: '#00ADEF',
                            label: {
                                color: '#0088C6',
                            },
                        },
                        {
                            name: 'Eligible Retail No.',
                            data: eligibleRetail,
                            type: 'bar',
                            barGap: '-100%',
                            barWidth: 20,
                            color: '#7FD6F7',
                            label: {
                                color: '#7FD6F7',
                                formatter: (params: any) => {
                                    return total[params.dataIndex];
                                },
                            },
                        },
                        {
                            name: 'Eligible Retail Passing Rate',
                            data: eligibleRetailPassingRate,
                            type: 'line',
                            yAxisIndex: 1,
                            color: '#FFBA3E',
                            label: {
                                color: '#FFBA3E',
                                formatter: '{c}%',
                            },
                        },
                    ],
                });
            }
        };

        watch(
            () => props.modalVisible,
            (val) => {
                if (val) {
                    if (myChart) {
                      myChart.clear();
                    }
                    getShowData().then(() => {
                        nextTick(showChart);
                    });
                }
            }
        );

        return {
            closeModal,
            chartRef,
        };
    },
});
