
import { defineComponent, ref, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import useRVSubmission from '@/hooks/SalesReporting/RVSubmission/OEM/useRVSubmission';
import ChartModal from './ChartModal.vue';
import { TreeSelect } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const tableWidth = window.innerWidth;
export default defineComponent({
  components: {
    ChartModal,
    ExclamationCircleOutlined
  },
  setup() {
    const spaceSize = 6;
    const searchCon = ref();
    const tableHeight = ref();
    nextTick(() => {
      (document.getElementsByClassName('table-box')[0] as any).style.height =
        window.innerHeight - 200 - searchCon.value.$el.scrollHeight + 'px';
      const height = (document.getElementsByClassName('table-box')[0] as any).style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf('px')));
      tableHeight.value = heightNum - 120;
    });

    const {
      searchParams,
      qmOptions,
      activeTab,
      tableData,
      columns,
      searchClick,
      resetClick,
      downloadClick,
      tabChange,
      openYear,
      handlerOpenChange,
      handlerPanelChange,
      chartVisible,
      openChart,
      closeChart,
      downloadVisible,
      openDownload,
      closeDownload,
      downloadColumnsData,
      downloadTableData,
      downloadRowSelection,
      select,
      initValue,
      brandList,
      regionList,
      handleBuChange,
      buList,
      routerJump,
      fetching,
      dealerGroupList,
      coficoCodeList,
      swtCodeList,
      dealerNameCnList,
      isRegion,
      customColumns
    } = useRVSubmission();

    return {
      isRegion,
      spaceSize,
      handleBuChange,
      searchCon,
      tableWidth,
      tableHeight,
      columns,
      searchParams,
      brandList,
      regionList,
      qmOptions,
      SHOW_PARENT,
      activeTab,
      tableData,
      searchClick,
      resetClick,
      downloadClick,
      tabChange,
      openYear,
      handlerOpenChange,
      handlerPanelChange,
      chartVisible,
      openChart,
      closeChart,
      downloadVisible,
      openDownload,
      closeDownload,
      downloadColumnsData,
      downloadTableData,
      downloadRowSelection,
      select,
      initValue,
      buList,
      routerJump,
      fetching,
      dealerGroupList,
      coficoCodeList,
      swtCodeList,
      dealerNameCnList,
      customColumns
    };
  }
});
